<template>
  <div style="margin: -15px -15px;background-color: #f0f2f5;">
    <!-- <a-spin :spinning="spinning"> -->
      <!-- 查询框 -->
      <div class="datePicker">
        <div>
          年份：
            <a-date-picker
              v-model="year"
              mode="year"
              v-decorator="['year']"
              placeholder="请输入"
              format="YYYY"
              class="input"
              @panelChange="panelChangeOne"
              style="width:70%;"
            />
        </div>
       <div class="date">
         备注：
         <a-input style="width: 180px;margin-right: 20px;"  placeholder="请输入" v-model="searchName" @keyup.enter="searchBtn"></a-input>
       </div>
       <a-button type="primary" style="margin-left: 30px;margin-right:30px" @click="searchBtn">查询</a-button>
       <div style="display: flex;align-items:center;margin-right: 50px;">
         <span>状态</span>: &nbsp;
         <a-radio-group  v-model="state" @change="searchOk">
           <a-radio-button value="0">
             已上架
           </a-radio-button>
           <a-radio-button value="1">
             已下架
           </a-radio-button>
         </a-radio-group>
       </div>
      </div>
      <!-- 展示表 -->
      <div style="background-color: #fff;padding: 20px; padding-bottom: 80px;">
        <a-button type="primary" style="margin-bottom: 20px;margin-right:3%;" @click="newlyBuilt">
          新增
        </a-button>
        <a-table :columns="columns" style="min-height: 500px;background-color: #fff;" :data-source="HeplTable" :rowKey="record => record.id"   :pagination="false">
          <span slot="operate" slot-scope="text,item,index">
            <a  style="margin-right: 10px" @click="recommend(item.id,item.otherId)">推广</a>
            <a @click="editBtn(item)" style="margin-right: 10px">修改</a>
            <a-popconfirm
                title="确定下架?"
                ok-text="是"
                cancel-text="否"
                @confirm="delBtn(item.id)"
            >
              <a  style="margin-right: 10px">下架</a>
            </a-popconfirm>
          </span>
        </a-table>
      </div>
    <!-- </a-spin> -->
  </div>
</template>

<script>
import moment from "moment";
import { current_date } from "@/utils/currentDate";
export default {
  data(){
    return{
      HeplTable:[],
      spinning:true,
      year: moment(current_date()).format('YYYY'),
      searchName: '',
      state:"1",
      columns:[
       {
         title: '图片',
         dataIndex: 'title',
         key: 'title',
         align:'center',
       },
       {
         title: '创建时间',
         align:'center',
         width:'15%',
         scopedSlots: { customRender: 'mark' }
       },
       {
         title: '操作',
         width: '200',
         scopedSlots: { customRender: 'operate' },
         fixed: 'right',
       },
     ],
    }
  },
  methods:{
    // 年份
    panelChangeOne(value) {
    //   this.year = value;
    //   this.pagination.pageNum = 1
    //   if(this.year){
    //     this.year = moment(this.year).format('YYYY')
    //   }
    //   console.log(this.year)
    //   //  刷新
    //   this.getTable()
    },
    // 搜索框查询
    searchBtn() {
    //  //重置页码
    //   this.pagination.pageNum = 1;
    //   this.pagination.pageSize = 10;
    //   this.pagination.current = 1
    //   this.getTable()
    },
    // 状态框查询
    searchOk(e) {
    //   switch (e.target.value) {
    //     // 已发布
    //     case '1':
    //       this.state = "1"
    //       this.pagination.pageSize = 10
    //       this.pagination.pageNum = 1
    //       this.pagination.current = 1
    //       this.getTable()
    //       break;
    //     // 未发布
    //     case '0':
    //       this.state = "0"
    //       this.pagination.pageSize = 10
    //       this.pagination.pageNum = 1
    //       this.pagination.current = 1
    //       this.getTable()
    //       break;
    //   }
    },
    // 新建表单
    newlyBuilt() {
    //   this.addForm = {}
    //   this.$set(this.addForm, 'type', "recruit")
    //   this.$set(this.addForm, 'isPublish', 1)
    //   this.$set(this.addForm, 'tags', [])
    //   this.$set(this.addForm, 'hospitalDepartmentId', [])
    //   this.$set(this.addForm, 'inputValue', '')
    //   this.addShow = true;
    //   this.getPCH()
    //   this.getHospitalOfficesList()
    //   this.GetSelectFrame()
    //   this.GetSelectSalary()
    //   this.GetSelectEducation()
    //   this.buttonType = "newBuild"
    //   this.expertForm={
    //    formType:'add',
    //    formShow: true,
    //    type: null,
    //  }
   },
  }
}
</script>

<style lang="scss" scoped>
.datePicker{
background-color: #fff;
width: 100%;
height: 64px;
padding: 20px;
display: flex;
align-items: center;
margin-bottom: 10px;
position: relative;

.status{
  float: right;
  position: absolute;
  right: 50px;
}
}
::v-deep .ant-table-thead>tr>th {
  font-weight: bold;
  
}
</style>